angular.module('fingerink')

    .directive('avanzadaDate', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-date.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            link: function link(scope, element) {
                element.find("input")[0].setAttribute("readonly", "true");
            },
            controllerAs: 'controller',
            controller: 'avanzadaDateCtrl'
        };
    })
    .controller('avanzadaDateCtrl', function ($rootScope, $scope) {
        var that = this;

        $scope.data.answer = new Date();
        $scope.$on('call' + $scope.data.id, function (event, data) {
            that.isOpen2 = true;
        });

    });
